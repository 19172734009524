<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step d-flex align-items-center mx-auto">
        <div>
          <p class="text-danger fw-bold text-capitalize" v-if="getErrors.payment">
            {{ getErrors.payment }}
          </p>

          <VeriffLogo />
          <h1>{{ $t("Verify my identity") }}</h1>
          <p class="fw-medium text-gray">
            {{
              $t(
                "First of, we’re going to double-check your identitfy uding our partner Veriff. This is as simple as the following steps:"
              )
            }}
          </p>

          <ul class="p-0 mb-4 fw-bold text-gray">
            <li>
              <ExclamationCircle fill="#168838" class="mr-2" />{{
                $t("Step 1: Scan QR code")
              }}
            </li>
            <li>
              <ExclamationCircle fill="#168838" class="mr-2" />{{
                $t("Step 2: Scan your id")
              }}
            </li>
            <li>
              <ExclamationCircle fill="#168838" class="mr-2" />{{
                $t("Step 3: Take a selfie")
              }}
            </li>
          </ul>
          <div class="w-100 mt-4">
            <div id="veriff-root"></div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <RightSection>
        <div class="step__rightSection mx-auto">
          <!-- <img src="../../../assets/images/expat/step6.png" alt=""> -->
          <RightPoints active="4" />
        </div>
      </RightSection>
    </template>
  </AuthLayoutContent>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RightSection from "@/components/common/RightSection.vue";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";
import ExclamationCircle from "../../../components/svg/ExclamationCircle.vue";
import { Veriff } from "@veriff/js-sdk";
import { createVeriffFrame, MESSAGES } from "@veriff/incontext-sdk";
import RightPoints from "./RightPoints.vue";
import VeriffLogo from "./VeriffLogo.vue";

export default {
  data() {
    return {
      veriff_logo: require("../../../assets/images/veriff-logo.png"),
    };
  },
  components: {
    RightSection,
    AuthLayoutContent,
    ExclamationCircle,
    RightPoints,
    VeriffLogo,
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("tenant", ["tenant"]),
  },
  created() {},
  async mounted() {
    let tenant_id = this.$route.params.tenant_id;

    if (tenant_id) {
      await this.getTenant(tenant_id);
    }
    const veriff = Veriff({
      host: "https://stationapi.veriff.com",
      apiKey: process.env.VUE_APP_VERIFF_KEY,
      parentId: "veriff-root",
      onSession: (err, response) => {
        console.log("session", response);
        // window.location.href = response.verification.url;
        // window.veriffSDK.createVeriffFrame({ url: response.verification.url });
        createVeriffFrame({
          url: response.verification.url,
          locale: this.tenant.locale,
          onEvent: (msg) => {
            console.log("onEvent", msg);
            switch (msg) {
              case MESSAGES.CANCELED:
                //
                break;
              case MESSAGES.FINISHED:
                this.$router.push({
                  name: "ExpatStep6.1",
                  params: { tenant_id: this.tenant.id },
                });
                break;
            }
          },
          lang: this.user.locale,
        });
      },
    });
    console.log("veriff", veriff);
    veriff.setParams({
      person: {
        givenName: this.user.first_name,
        lastName: this.user.last_name,
      },
      vendorData: JSON.stringify({
        tenant_id: this.tenant.encrypted_id,
      }),
    });

    veriff.mount({
      submitBtnText: this.$t("Start verification with Veriff"),
      submitBtnClass: "btn-primary",
    });
  },
  methods: {
    ...mapActions("tenant", ["updateTenant", "getTenant"]),
  },
};
</script>

<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 412px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__heading {
    @include font(1.375rem, $primary, 700);
  }

  &__rightSection {
    width: 362px;

    @include xs {
      width: 93%;
    }
  }
}

#veriff-root {
  max-width: 100% !important;
}

#veriff-submit-btn {
  color: #ffffff;
  background-color: #0a9250;
  border-color: #0a9250;
  font-size: 16px !important;
  padding: 16px 32px !important;
  height: auto;
  line-height: 1;
}
</style>
